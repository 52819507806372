<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header.vue";
import Axios from "@/plugins/axios";
import UserTableCard from "@/components/dashboard/user/user-table-card.vue";
import DataLoader from "@/components/data-loader.vue";
import UserFormModal from "@/components/dashboard/user/user-form-modal.vue";
import Pagination from "@/components/dashboard/Pagination.vue";
import {ADMIN_} from "@/helpers/constant/roles";

export default {
  page: {
    title: "User",
    meta: [{ name: "description", content: 'User Management' }],
  },
  components: {
    Pagination,
    UserFormModal,
    DataLoader,
    UserTableCard,
    Layout,
    PageHeader,
  },
  data() {
    return {
      users: [],
      showDataLoader: true,
      userFormModal: false,
      activeUser: null,
      next: null,
      previous: null,
      query: '',
      page: 1,
      totalItems: 0,
      url: ''
    }
  },
  mounted() {
    this.initUrl()
    this.getUsers()
  },
  methods: {
    ADMIN_() {
      return ADMIN_
    },
    openAddUserModal(){
      this.userFormModal = true
    },
    openUpdateUserModal(user){
      this.activeUser = user
      this.userFormModal = true
    },
    closeModal(type){
      switch (type) {
        case 'user-form':
          this.activeUser = null
          this.userFormModal = false
          break
        case 'user-update-form':
          this.activeUser = null
          this.userFormModal = false
          break
      }
    },
    updateUserTable(user){
      this.users = this.users.filter(u => u.id !== user.id)
      this.users.unshift(user)
    },
    searchUser(searchBody){
      const {query} = searchBody
      this.query = query
      this.showDataLoader = true
      this.users = []
      this.initUrl()
      this.getUsers()
    },
    getUsers(){
      Axios.get(this.url)
          .then(response => {
            this.setPagination(response)
            response.data["hydra:member"].map(user => {
              this.users.push(user)
            })

            this.showDataLoader = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    initUrl(){
      this.url = '/users/get/by_roles?role=supervisor&page=' + this.page + '&query=' + this.query
    },
    setPagination(response){
      if (response.data["hydra:view"]["hydra:next"]) {
        this.totalItems = response.data["hydra:totalItems"]
        this.next = response.data["hydra:view"]["hydra:next"]
        this.previous = response.data["hydra:view"]["hydra:previous"]
      }
    },
    nextPage(){
      this.setCurrentPage(this.next)
      this.users = []
      this.getUsers()
    },
    previousPage(){
      this.setCurrentPage(this.previous)
      this.users = []
      this.getUsers()
    },
    navigate(page){
      this.page = page
      this.users = []
      this.showDataLoader = true
      this.initUrl()
      this.getUsers()
    },
    setCurrentPage(url){
      const currentPage = Number(url.split('page=')[1])
      if (currentPage !== this.page) this.page = currentPage
      this.url = url.split('api/')[1]
      this.showDataLoader = true
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
        :title="'Superviseurs'"
        :button-title="'+ Superviseurs'"
        @clickButton="openAddUserModal"
        @submitSearch="searchUser"
    />
<!--    <Notification/>-->
    <UserFormModal
        @onModalClose="closeModal"
        @userCreated="updateUserTable"
        :activeUser="activeUser"
        v-model="userFormModal"
        :title="'Créer un Superviseur'"
        :user-role="'supervisor'"
    />
    <DataLoader v-if="showDataLoader"/>
    <section>
      <div class="" v-if="users.length > 0 && !showDataLoader">
        <div class="card">
          <div class="card-body">
            <UserTableCard
                :users="users"
                @openUpdateUserModal="openUpdateUserModal"
                page-name="supervisor"
                :role="ADMIN_()"
            />
            <Pagination
                :next="next"
                :previous="previous"
                :currentPage="page"
                :totalItems="totalItems"
                @navigate="navigate"
                @nextPage="nextPage"
                @previousPage="previousPage"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>